<template>
    <div :class="`m-3 wrapper  wrapper--default`">
        <div class="text-center p-2">
            <h2>Verwacht deze week</h2>
            <span @click="previousWeek"><i class="fas fa-arrow-left"></i></span>
            <span class="text-center m-2">Week: {{ week_number }}, {{ year_number }}</span>
            <span @click="nextWeek"><i class="fas fa-arrow-right"></i></span>
        </div>
        <Loading v-if="loading" />
        <div class="p-3" v-else>
            <h5>Vandaag worden er <strong>{{ expected_today }} {{ bu }}</strong> verwacht.</h5>
            <hr>
            <table :class="`w-100 table__border--${color}`">
                <thead :class="`table__head--${color}`">
                    <tr>
                        <th class="table__head--text">Status</th>
                        <th class="table__head--text">Item</th>
                        <th class="table__head--text">Inkoper</th>
                        <th class="table__head--text">Inkoopprijs</th>
                        <th class="table__head--text">Kostenbedrag</th>
                        <th class="table__head--text">Voertuig</th>
                        <th class="table__head--text" style="width:105px;">Verwacht</th>
                        <th class="table__head--text">Bet.</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, key) in expected_per_week" :key="key" :class="`table__row--color`">
                        <td class="table__cell--default">
                            <div v-if="item.statusnaam == 'nieuw'" class="text-center">
                                <span class="dot dot--red " v-b-tooltip.hover title="Nieuw"></span>
                            </div>
                            <div class="text-center" v-else>
                                <span class="dot dot--blue" v-b-tooltip.hover title="Ingepland"></span>
                            </div>
                        </td>

                        <td class="table__cell--default">
                            <ImageHover :itemnummer="item.itemnummer" :bu="color" />
                        </td>
                        <td class="table__cell--default">{{ item.naam }}</td>
                        <td class="table__cell--default table__data--right">€ {{ item.inkoopprijs }}</td>
                        <td class="table__cell--default table__data--right">€ {{ item.kosten }}</td>
                        <td class="table__cell--default">{{ item.voertuig }}</td>
                        <td class="table__cell--default">{{ item.aanleverdatum }}</td>
                        <td class="table__cell--default">
                            <div v-if="item.betaald == 1">
                                <span v-b-tooltip.hover :title="item.betaaldatum"><i class="fas fa-euro-sign"></i></span>
                            </div>
                            <div v-else>
                                <span v-b-tooltip.hover title="Nog niet betaald"><i
                                        class="fab fa-creative-commons-nc-eu"></i></span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
  
<script>
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";
import ImageHover from "@/components/ImageHover.vue";
import moment from "moment";
import {
    addTime,
    subtractTime,
    getWeekNumber,
    getYearNumber,
} from "@/functions/date.js";

export default {
    props: ["bu"],
    components: { Loading, ImageHover },
    data: () => ({
        loading: true,
        full_date: moment(),
        week_start: null,
        week_end: null,
        expected_per_week: null,
        expected_today: null,
        color: null,
        week_number: null,
        year_number: null,
    }),
    created() {
        this.getData(this.bu);
    },
    watch: {
        bu(newbu) {
            this.getData(newbu)
        }
    },
    methods: {
        getData(bu) {
            (this.bu === 'vans') ? this.color = '210' : this.color = '200';
            this.loading = true;
            this.week_start = this.full_date.startOf("week").format("YYYY-MM-DD");
            this.week_end = this.full_date.endOf("week").format("YYYY-MM-DD");
            this.week_number = getWeekNumber(this.full_date);
            this.year_number = getYearNumber(this.full_date);
            request(
                `purchaseduo/expected-this-week/${bu}/${this.week_start}/${this.week_end}`,
                "GET"
            ).then(({ expected_per_week, expected_today }) => {
                this.expected_today = expected_today;
                this.expected_per_week = expected_per_week;
                this.loading = false;
            });
        },
        previousWeek() {
            this.full_date = subtractTime(7, this.full_date, "days");
            this.getData(this.bu);
        },
        nextWeek() {
            this.full_date = addTime(7, this.full_date, "days");
            this.getData(this.bu);
        },
    },
};
</script>
  